/* CSS for the 'admin-admin' class */
.admin-admin {
    background-color: #f0f0f0;
    padding: 20px;
    font-family: Arial, sans-serif;
}

/* CSS for the 'admin-investor-box' class */
.admin-investor-box {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

/* CSS for the 'admin-flip-view' anchor tag */
.admin-flip-view a {
    color: #007bff;
    text-decoration: none;
}

.reports-container {
    display: flex;
    justify-content: space-between;
}

.profiles-container {
    flex: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    text-align: center;
}

.profiles-container h1 {
    font-size: 18px;
    margin-bottom: 10px;
}

.user-info {
    margin-top: 15px;
}

.user-info h5 {
    font-size: 16px;
    margin-bottom: 5px;
}

.user-info p {
    font-size: 14px;
    margin: 5px 0;
}

#user-management-form {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

#user-management-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

#user-management-form input[type="text"],
#user-management-form input[type="email"],
#user-management-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

#user-management-form button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

#user-management-form button[type="submit"]:hover {
    background-color: #0056b3;
}

/* Style for the 'Admin' heading */
h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.admin-cont {
    display: block;
    margin: auto;
    border-left: 2px solid #cacaca;
    border-right: 2px solid #cacaca;
    border-radius: 10px;
    max-width: 90%;
}

/* Style for the row and columns */
.row {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    justify-content: space-evenly;
}

.col-md-3 {
    flex: 0 0 25%;
    /* 25% width for each column */
    padding: 10px;
    /* Add padding to each column */
    box-sizing: border-box;
    /* Include padding in column width calculation */
}

/* Style for the 'admin-investor-box' class */
.admin-investor-box {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
    margin: 20px 0px;
}

.admin-investor-box:hover {
    transform: scale(1.05);
    /* Scale up the box on hover */
}

.admin-investor-box h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

/* Style for the 'admin-flip-view' class */
.admin-flip-view a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
}

.admin-flip-view a:hover {
    color: #0056b3;
    /* Change link color on hover */
}