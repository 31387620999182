/* Add your CSS styles here */
.home-container {
    max-width: 90%;
    margin: 50px auto;
}


.home-row {
    /* Add any row styles here */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-column {
    /* Add any column styles here */
    flex: 1;
    margin: 10px;
}

.home-image {
    /* Add any image styles here */
    max-width: 100%;
    height: auto;
}

.home-buttons {
    /* Add any button container styles here */
    text-align: center;
}

.home-button {
    /* Add button styles here */
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
}

.home-button:hover {
    background-color: #0056b3;
}
@media  screen and  (max-width:750px){
    .home-row{
        flex-direction:column-reverse;
        width : 100%;
        justify-content: flex-end;
    }
}