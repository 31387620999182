/* Styles for the QA Officer Monitor Reports container */
.qa-officer-monitor-reports {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f0f0f0;
}

/* Styles for the reports table */
table.reports-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

/* Styles for table headers */
table.reports-table th {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    text-align: left;
}
.table1{
    /* display: none; */
     width:100%; 
    overflow-x: auto !important;
}
/* Styles for table rows */
table.reports-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Styles for table cells */
table.reports-table td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

/* Style for the 'Monitor Student Reports' heading */
h2 {
    font-size: 24px;
    margin-bottom: 20px;
}