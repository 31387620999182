/* UserDetailsForm.css */

.user-details-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    max-width: 400px;
    width: 100%;
    z-index: 1000;
}

.user-details-form h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.user-details-form label {
    font-weight: bold;
}

.user-details-form form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-details-form input,
.user-details-form textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.user-details-form button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
}

.user-details-form button:hover {
    background-color: #0056b3;
}

/* UserDetailsForm.css */

/* ... (previous styles) */

.user-details-form .form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.user-details-form .form-buttons button {
  background-color: #ff3b3b;
  color: #fff;
}

.user-details-form .form-buttons button:hover {
  background-color: #d63030;
}
