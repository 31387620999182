/* ExamForm.css */
.exam-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.exam-popup {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
}

.exam-popup h2 {
    text-align: center;
}

.exam-popup form {
    display: flex;
    flex-direction: column;
}

.exam-popup div {
    margin-bottom: 10px;
}

.exam-popup label {
    font-weight: bold;
}

.exam-popup input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
}

.exam-popup .form-buttons {
    display: flex;
    justify-content: space-between;
}

.exam-popup button {
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
}

.exam-popup button:hover {
    background-color: #0056b3;
}
@media screen and (max-width:660px){
    .exam-popup{
        max-width:80vw;
    }
}