/* ProgramCoordinator.css */
.program-coordinator {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

/* Add New Student section */
input[type="text"] {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Student Performance Data section */
.student-performance ul {
    list-style: none;
    padding: 0;
}

.student-performance li {
    margin-bottom: 10px;
}

button.delete-button {
    background-color: red;
}

/* Chat section */
.chat {
    margin-top: 20px;
}

.chat-messages {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
}

.chat-message {
    margin-bottom: 5px;
}

.chat-sender {
    font-weight: bold;
    margin-right: 5px;
}

.chat-input {
    margin-top: 10px;
}

.chat-input input[type="text"] {
    width: 80%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.chat-input button {
    width: 18%;
    padding: 5px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* ProgramCoordinator.css */
/* ... (previous styles) */

/* Table styles */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  table th, table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }
  
  table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  table tr:hover {
    background-color: #ddd;
  }
  
  /* Update Performance input style */
  table input[type="text"] {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Delete button style */
  table button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 10px;
  }
  
  table button:hover {
    background-color: darkred;
  }
  
  .file-management{
    margin: 50px auto;
    border-left: 2px solid red;
    border-right: 2px solid red;
    border-radius: 10px;
  }

  /* Add this CSS to your stylesheet or component */

.student-inquiries ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .student-inquiries li {
    margin-bottom: 20px;
  }
  
  .message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 8px;
  }
  
  .message-container p {
    margin: 0;
    font-weight: bold;
  }
  
  .message-container button {
    align-self: flex-end;
    margin-top: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .message-container button:hover {
    background-color: #0056b3;
  }
  
  .reply-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
  }
  
  textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .inquiry-input button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .inquiry-input button:hover {
    background-color: #0056b3;
  }
  