.card{
    border: 2px solid gray;
    width: 35%;
    padding: 10px;
    border-radius: 40px;
    margin: auto;
}
.card-body{
    background: rgb(211, 209, 209);
    padding: "40px";
    width: 100%;
    margin: auto;
    border-radius: 40px;
}
.butt{
    display:block;
    margin: 10px auto;
    background-color: #007bff;
    color: #fff;
    border: none;
        width: 180px;
    border-radius: 5px;
    padding: 10px 20px; 
}
@media screen and (max-width:470px){
    .buttons{
        display: flex;
        flex-direction:column !important;
    }
    .card{
        width:70%;
    }
}
@media screen and (max-width:650px){
    .card{
        width:70%;
    }

}