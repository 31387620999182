/* Navbar.css */
.nav-container {
    background-color: #333;
    color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-logo {
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: white;
}

.nav-links {
    list-style: none;
    padding: 0;
    display: flex;
}

.nav-links li {
    display: inline;
    margin-right: 20px;
}

.nav-links a {
    text-decoration: none;
    color: white;
}
.nav-links1 {
    list-style: none;
    padding: 0;
    display: flex;
}

.nav-links1 li {
    display: inline;
    margin-right: 20px;
}

.nav-links1 a {
    text-decoration: none;
    color: white;
}
.nav-responsive{
    display: none;

}
.bar{
    font-size:16px;
color:white;
}
.active{
    display:none;
}
.menu{
    /* background:none; */
    background-image: url('/public/menu.png');
    width:40px;
    height:40px;
    float:right;

}
@media screen and (max-width: 750px){
    
    .nav-links{
        
        flex-direction:column !important; 
        position:absolute;
        background:#333 !important;
        display: none;
        top:56px;
        left:0;
        width:100%;
        padding:20px;
        
    }
    .nav-links1{
        flex-direction:column !important; 
        position:absolute;
        background:#333 !important;
        /* display: none; */
        top:56px;
        left:0;
        width:100%;
        padding:20px;
    }
    .nav-links li{
        padding:10px;
    }
    .nav-responsive{
        display: block;
        float:right;
        color:white !important;
        font-size:large;
        width:20%;
        margin-right:10px;
    }
}
@media screen and (max-width:400px){
    .nav-responsive{
        display: block;
        float:right;
        color:white !important;
        font-size:large;
        width:20%;
        margin-right:20px;
    }   
}