/* Footer.css */
footer {
    background-color: #333;
    color: white;
}

.footer {
    max-width: 90%;
    margin: auto;
    padding: 20px 0;
    display: flex;
    
    /* gap: 10%; */
}

.links-style{
    color: white;
}

.footer img {
    max-width: 250px;
    height: auto;
    margin-top: 30px;
}

.footer-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width:100% !important;
    /* gap: 20%; */
    padding:10px;
    /* min-width:200px; */
}

/* .footer-list>div {
    /* border: 1px solid red; 
    min-width: 200px;
    padding: 10px;
} */

.footer ul {
    list-style: none;
    padding: 0;
}

.footer ul li {
    margin-bottom: 10px;
    color: white;
}

.footer ul li a {
    text-decoration: none;
    color: black;
}

.footer h4 {
    font-size: 18px;
    font-weight: bold;

}

.footer p {
    font-size: 14px;
}

.footer-bottom {
    background-color: black;
    color: #e7e8ed;
    text-align: center;
    margin-bottom: 0px;
}

.footer-bottom a {
    text-decoration: none;
    color: #e7e8ed;
}

.footer-bottom p {
    margin: 0px;
    padding: 5px;
}
@media screen and (max-width:450px){
   .footer img{
    width:35vw;
   }
   .footer span{
    font-size: 4vw !important;
   }
   .fa{
    /* margin-left:10px; */
    font-size: 2.5vw !important;
   }
}
@media screen and (max-width:750px){
    .footer img{
        max-width:30vw;
    };
    .footer-list>div{
        min-width:0px !important;
    }
    .footer h4 {
        font-size: 15px;
        font-weight: bold;
    
    }
    
    .footer p {
        font-size: 12px;
    }
    .footer ul li a {
        text-decoration: none;
        color: black;
        font-size:13px;
    }
}
