.image-profile{
    max-width: 25%;
}
.profile{
    margin: 80px auto;
       text-align: center;
       display: flex;
       justify-content: center,
}
@media screen and (max-width:650px){
    .image-profile{
        max-width:40vw;
        height:40vh;
        padding:30px;
        margin-left:50px;
    }
    .profile{
        flex-direction: column;
    }
    h6{
        padding:5px;
    }
}
@media screen and (max-width:450px){
    .image-profile{
        max-width:250px;;
        height:250px;
        padding:30px;
        margin-left:50px;
    }
}