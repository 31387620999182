/* Services.css */

.service-container {
    max-width: 90%;
    margin: 50px auto;
}

.service-title {
    font-size: 24px;
    margin-bottom: 16px;
}

.service-subtitle {
    font-size: 20px;
    margin-top: 24px;
    margin-bottom: 16px;
}

.service-list {
    list-style-type: disc;
    /* margin-left: 20px; */
}


.service-list li {
    margin-bottom: 12px;
}