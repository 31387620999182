/* About.css */
.about-container {
    max-width: 90%;
    margin: 50px auto;
}

.about-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
}

.about-content {
    margin: 20px 0px;
    font-size: 16px;
    line-height: 2;
}